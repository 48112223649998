import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell carnós hemisfèric que s’obre ràpidament, de 2 a 5 cm de diàmetre, de color palla torrada. Els exemplars joves presenten el marge amb restes flaconoses del vel general, davall el capell es troben les làmines amb nombroses lamel·les de coloració varible passant del blanc al violeta per acabar grises. Les espores, de color gris a violaci en massa, són ovals o un poc el·líptiques, de 7-10 x 5-6 micres. El peu, curt, robust i de color més clar que el capell porta un anell estret amb estries de color violaci a la part superior.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      